<template>
  <BaseCard
    title="datos de la categoría"
    :loading="loading"
    @save-button-click="handleSaveButtonClick"
  >
    <CategoryForm ref="category-form" />
  </BaseCard>
</template>

<script>
import CategoriesApi from '@/api/categories-api'
import CategoryForm from '@/components/category/form/CategoryForm.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: {
    BaseCard,
    CategoryForm,
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async handleSaveButtonClick() {
      const formData = await this.$refs['category-form'].submitForm()

      if (!formData.success) {
        return
      }

      await this.createCategory(formData.category)
    },
    async createCategory(category) {
      this.loading = true
      try {
        const response = await CategoriesApi.create(category)
        await this.$router.replace({ name: 'viewCategory', params: { id: response.data.id } })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
